

import { createSlice } from '@reduxjs/toolkit';
import { getTinTucAPI, getTinTucDetailAPI } from '../api/api';

const initialState = {
  data: [],
  tinTucDetail: {}
};

const tinTucReducer = createSlice({
  name: 'tinTucReducer',
  initialState,
  reducers: {

    getTinTucActions(state, action) {

      state.data = action.payload;
    },
    getTinTucDetailActions(state, action) {

      state.tinTucDetail = action.payload;
    },
  },
});

export const { getTinTucActions, getTinTucDetailActions } = tinTucReducer.actions;

export default tinTucReducer.reducer;



export const getTinTucDis = () => {
  return async (dispatch) => {
    const result = await getTinTucAPI();
    const action = getTinTucActions(result);
    dispatch(action);
  };
};


export const getTinTucDetailDis = (id) => {
  return async (dispatch) => {
    const result = await getTinTucDetailAPI(id);
    const action = getTinTucDetailActions(result);
    dispatch(action);
  };
};


