import { Collapse, Divider, Modal, Popconfirm, Select, Tag, TreeSelect, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { checkQuyen } from '../utils/config'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDanhMucDis, getBangDiemDis } from '../redux/bangDiemReducer';
import { suaDanhMucApi, themDanhMucApi, xoaMucApi } from '../api/bangDiemApi';
import NoiDungBangDiem from '../components/NoiDungBangDiem';

const BangDiem = () => {

    const [valueDM, setValueDM] = useState({ ma_cha: 0, danh_muc: '' });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { danhMuc } = useSelector((state) => state.bangDiemReducer);
    const { dataBangDiemChiTiet } = useSelector((state) => state.bangDiemReducer);

    const { id } = useParams()

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        dispatch(getDanhMucDis())

    }, [])

    const convertToTreeData = (data) => {
        const map = {};
        const roots = [];

        data.forEach((item) => {
            map[item.id] = {
                ...item, title: <>
                    {item.danh_muc}
                    {checkQuyen() && <>
                        <Tag className='mx-2' color='blue' onClick={() => {
                            setValueDM({
                                ...item
                            })
                            setIsModalOpen(true)
                        }}><i className='fas fa-edit'></i></Tag>

                        {item.dc_xoa &&
                            <Popconfirm
                                title="Bạn chắc muốn tiếp tục ?"
                                onConfirm={() => {
                                    xoaMucApi(item.id).then(res => {
                                        message.success("Xóa thành công !")
                                        dispatch(getDanhMucDis())
                                    })
                                }}
                                okText="Có"
                                cancelText="Không"
                            >
                                <Tag color='red'><i className='fas fa-trash'></i></Tag>
                            </Popconfirm>}
                    </>}
                </>, value: item.id, key: item.id, children: [], selectable: item.ma_cha == 0 ? false : true
            };
        });

        data.forEach((item) => {
            if (item.ma_cha === 0) {
                roots.push(map[item.id]);
            } else {
                if (map[item.ma_cha]) {
                    map[item.ma_cha].children.push(map[item.id]);
                }
            }
        });

        return roots;
    };

    const onChangeDanhMuc = (value, name) => {

        setValueDM({
            ...valueDM,
            [name]: value
        })
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const treeData = convertToTreeData(danhMuc)

    const itemSelect = [{
        value: 0,
        label: "Bảng điểm"
    }]


    danhMuc.filter(n => n.ma_cha == 0).map(item => {
        itemSelect.push({
            value: item.id,
            label: item.danh_muc,
        })
    })



    return (
        <div>
            <>
                <Divider />
                {checkQuyen() && <button className='btn btn-success btn-sm my-2' onClick={() => {
                    setValueDM({
                        danh_muc: "",
                        ma_cha: 0,
                        id: 0
                    })
                    setIsModalOpen(true)
                }} ><i className='fas fa-plus'></i>  Thêm bảng điểm/danh mục </button>}

                <br />

                {/* <p className='container'>
                    {checkQuyen() && <button className='btn btn-success mx-2' onClick={() => {
                        setOpen(true)
                        setNguoiDung({ id: 0 })
                    }} ><i className='fas fa-plus'></i>  Thêm tin mới</button>}
                  
                </p> */}
                <label>Bảng điểm/Danh mục: </label>
                <TreeSelect
                    showSearch
                    style={{
                        width: '100%',
                    }}


                    placeholder="Chọn danh mục bảng điểm"
                    treeNodeFilterProp="danh_muc"
                    onChange={(value) => {

                        navigate(`/bang-diem/${value}`)
                    }}
                    treeData={treeData}
                />
                <Divider />
            </>

            {id && <NoiDungBangDiem />}

            <Modal title="Danh mục" open={isModalOpen} onCancel={() => setIsModalOpen(false)} onOk={() => {

                if (valueDM.danh_muc == '') {
                    message.error("Tên danh mục không được trống !")
                    return;
                }

                if (valueDM.id == 0) {
                    delete valueDM.id
                    themDanhMucApi(valueDM).then(res => {
                        message.success("Thêm thành công !")
                        setIsModalOpen(false)
                        dispatch(getDanhMucDis())
                    })
                } else {

                    delete valueDM.dc_xoa

                    suaDanhMucApi(valueDM.id, valueDM).then(res => {
                        message.success("Cập nhật thành công !")
                        setIsModalOpen(false)
                        dispatch(getDanhMucDis())
                    })
                }

            }}>
                <div className="mb-3">
                    <label className="form-label" >Tên danh mục</label>
                    <input className="form-control" value={valueDM?.danh_muc} onChange={(e) => onChangeDanhMuc(e.target.value, "danh_muc")} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Danh mục cha</label>

                    <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Chọn danh mục cha"
                        optionFilterProp="children"
                        value={valueDM.ma_cha}
                        onChange={(value) => onChangeDanhMuc(value, "ma_cha")}
                        filterOption={filterOption}
                        options={itemSelect}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default BangDiem