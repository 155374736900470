

import { createSlice } from '@reduxjs/toolkit';
import { getDanhMucApi, getBangDiemApi, getBangDiemChiTietApi, getBangDiemChiTietDanhMucApi } from '../api/bangDiemApi';


const initialState = {
  data: [],
  dataBangDiemChiTiet: [],
  danhMuc: [],

  dataBangDiemChiTietDanhMuc: [],

};

const bangDiemReducer = createSlice({
  name: 'bangDiemReducer',
  initialState,
  reducers: {

    getBangDiemActions(state, action) {

      state.data = action.payload;
    },
    getBangDiemChiTietActions(state, action) {

      state.dataBangDiemChiTiet = action.payload;
    },
    getDanhMucActions(state, action) {

      state.danhMuc = action.payload;
    },

    getBangDiemChiTietDanhMucActions(state, action) {

      state.dataBangDiemChiTietDanhMuc = action.payload;
    },

  },
});

export const { getBangDiemActions, getDanhMucActions, getBangDiemChiTietActions,
  getBangDiemChiTietDanhMucActions
} = bangDiemReducer.actions;

export default bangDiemReducer.reducer;


export const getBangDiemDis = (id) => {
  return async (dispatch) => {
    const result = await getBangDiemApi(id);
    const action = getBangDiemActions(result);
    dispatch(action);
  };
};

export const getBangDiemChiTietDis = (userId) => {
  return async (dispatch) => {
    const result = await getBangDiemChiTietApi(userId);
    const action = getBangDiemChiTietActions(result);
    dispatch(action);
  };
};

export const getDanhMucDis = () => {
  return async (dispatch) => {
    const result = await getDanhMucApi();
    const action = getDanhMucActions(result);
    dispatch(action);
  };
};


export const getBangDiemChiTietDanhMucDis = (maDanhMuc) => {
  return async (dispatch) => {
    const result = await getBangDiemChiTietDanhMucApi(maDanhMuc);
    const action = getBangDiemChiTietDanhMucActions(result);
    dispatch(action);
  };
};



