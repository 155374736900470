import { Collapse, Divider, Drawer, Input, Popconfirm, Switch, Table, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { checkQuyen, formatDate } from '../utils/config';
import { getDanhMucMoHinhDis, getMoHinhDis, getMoHinhNguoiDungDis } from '../redux/moHinhReducer';
import { exportMoHinh, suaMoHinhApi, xoaMoHinhApi } from '../api/moHinhApi';
import { getCauHinhAPI, suaCauHinhAPI } from '../api/api';
import FormDanhMucMoHinh from '../components/FormDanhMucMoHinh';
import { getNguoiDungDis } from '../redux/nguoiDungReducer';

const { Search } = Input;

let lstYear = []
const MoHinhGiaiPhap = () => {
    const [themMoHinh, setThemMoHinh] = useState("1");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data } = useSelector((state) => state.moHinhReducer);
    const { data: dataUser } = useSelector((state) => state.nguoiDungReducer);

    const { id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();

    const { dataDanhMuc } = useSelector((state) => state.moHinhReducer);


    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))


    useEffect(() => {

        if (checkQuyen())
            dispatch(getMoHinhDis())
        else
            dispatch(getMoHinhNguoiDungDis(userLogin.id))

        getCauHinhAPI("THEM_MO_HINH").then(res => {

            setThemMoHinh(res.gia_tri)
        })

        dispatch(getDanhMucMoHinhDis())
        dispatch(getNguoiDungDis())

    }, [])

    let columns = [
        {
            title: 'Loại mô hình',
            key: 'loai_mo_hinh',
            render: (_, record) => {
                let danhMuc = dataDanhMuc.find(n => n.id == record.loai_mo_hinh)
                return <>
                    {danhMuc?.ten_danh_muc}
                </>
            }
        },
        {
            title: 'Tên mô hình',
            key: 'ten_mo_hinh',
            render: (_, record) => {
                return <>
                    {record?.ten_mo_hinh}
                </>
            }
        },
        {
            title: 'Quy mô',
            key: 'quy_mo',
            render: (_, record) => {
                let danhMuc = dataDanhMuc.find(n => n.id == record.quy_mo)
                return <>
                    {danhMuc?.ten_danh_muc}
                </>
            }
        },
        {
            title: 'Đơn vị tổ chức',
            key: 'don_vi_to_chuc',
            render: (_, record) => {
                return <>
                    {record.don_vi_to_chuc}
                </>
            }
        },
        {
            title: 'Ngày tạo',
            key: 'ngay_tao',
            render: (_, record) => {
                return <>
                    {formatDate(record.ngay_tao)}
                </>
            }
        },
        {
            title: 'Bắt đầu',
            key: 'ngay_bat_dau',
            render: (_, record) => {
                return <>
                    {formatDate(record.ngay_bat_dau)}
                </>
            }
        },
        {
            title: 'Số lượng tham gia',
            key: 'so_luong_tham_gia',
            render: (_, record) => {
                return <>
                    {record.so_luong_tham_gia.toLocaleString()}
                </>
            }
        },
        {
            title: 'Tổng kinh phí',
            key: 'kinh_phi',
            render: (_, record) => {
                return <>
                    {Number(record.kinh_phi).toLocaleString()} VNĐ
                </>
            }
        },

        {
            title: 'Xét duyệt',
            key: 'kinh_phi',
            render: (_, record) => {
                return <>
                    {
                        checkQuyen() &&
                        <p>
                            <Switch checked={record.da_duyet} onChange={(value) => {
                                let model = { ...record, da_duyet: value }

                                suaMoHinhApi(record.id, model).then(res => {
                                    if (checkQuyen())
                                        dispatch(getMoHinhDis())
                                    else
                                        dispatch(getMoHinhNguoiDungDis(userLogin.id))
                                })
                            }} />
                        </p>
                    }

                    {record.da_duyet ?
                        <Tag color='green'>Đã duyệt</Tag>
                        :
                        <Tag color='red'>Chưa duyệt</Tag>
                    }
                </>
            }
        },
        {
            title: 'Đăng ký/báo cáo',
            key: 'kinh_phi',
            render: (_, record) => {
                return !checkQuyen() && <>
                    {record.da_duyet ?
                        <Tooltip title="Không thể sửa khi đã duyệt !">
                            <Switch className='opacity-50' value={record.dang_ky} />
                        </Tooltip>

                        :
                        <Switch checked={record.dang_ky} onChange={(value) => {
                            let model = { ...record, dang_ky: value }

                            suaMoHinhApi(record.id, model).then(res => {
                                if (checkQuyen())
                                    dispatch(getMoHinhDis())
                                else
                                    dispatch(getMoHinhNguoiDungDis(userLogin.id))
                            })
                        }} />
                    }


                </>
            }
        },
        {
            title: ' ',
            key: 'action',
            render: (_, record) => {
                return <>

                    {checkQuyen() ?
                        <button className='btn btn-sm btn-primary m-2' onClick={() => {
                            navigate(`/update-mo-hinh/${record.id}`)
                        }}>
                            <i className='fas fa-eye'></i>
                        </button>
                        :
                        <>
                            {
                                record.da_duyet ?
                                    <Tooltip title="Không thể chỉnh sửa khi đã duyệt !">
                                        <button className='opacity-50 btn btn-sm btn-primary m-2' >
                                            <i className='fas fa-edit'></i> Xem/Sửa
                                        </button>
                                    </Tooltip>

                                    :
                                    <button className='btn btn-sm btn-primary m-2' onClick={() => {
                                        navigate(`/update-mo-hinh/${record.id}`)
                                    }}>
                                        <i className='fas fa-edit'></i> Xem/Sửa
                                    </button>
                            }


                            {
                                record.da_duyet ?
                                    <Tooltip title="Không thể xóa khi đã duyệt !">
                                        <button className='opacity-50 btn btn-sm btn-danger m-2'>
                                            <i className='fas fa-trash'></i>
                                        </button>
                                    </Tooltip>

                                    :
                                    <Popconfirm
                                        title="Bạn chắc muốn tiếp tục ?"
                                        onConfirm={() => {
                                            xoaMoHinhApi(record.id).then(res => {

                                                if (checkQuyen())
                                                    dispatch(getMoHinhDis())
                                                else
                                                    dispatch(getMoHinhNguoiDungDis(userLogin.id))

                                            })
                                        }}
                                        okText="Có"
                                        cancelText="Không"
                                    >
                                        <button className='btn btn-sm btn-danger m-2'>
                                            <i className='fas fa-trash'></i>
                                        </button>

                                    </Popconfirm>
                            }



                        </>
                    }

                </>
            }
        },
    ]


    // set lst year
    data.map(item => {
        let date = new Date(item.ngay_bat_dau)
        let year = date.getFullYear()
        if (!lstYear.find(n => n == year))
            lstYear.push(year)
    })

    let dataSource = data;

    if (searchParams.get("tmh")) {
        let txtValue = searchParams.get("tmh")
        dataSource = dataSource.filter(item => item.ten_mo_hinh.trim().toLowerCase().indexOf(txtValue.trim().toLowerCase()) != -1)
    }

    if (searchParams.get("xd")) {
        let txtValue = searchParams.get("xd")

        dataSource = dataSource.filter(item => item.da_duyet == +txtValue)
    }

    if (searchParams.get("time")) {
        let txtValue = searchParams.get("time")

        dataSource = dataSource.filter(item => {
            let yearItem = new Date(item.ngay_bat_dau).getFullYear()
            if (yearItem == +txtValue) {
                return item
            }
        })
    }


    const xuatNoiDungTable = (nguoiDungId) => {
        return <Table columns={columns} dataSource={dataSource.filter(n => n.nguoi_dung_id == nguoiDungId).sort((a, b) => b.id - a.id)}
            rowKey="id"
        />

    }


    let itemCollapse = []
    data.map(item => {

        if (!itemCollapse.find(n => n.key == item.nguoi_dung_id)) {
            let nguoiDung = dataUser.find(n => n.id == item.nguoi_dung_id)
            if (nguoiDung)
                itemCollapse.push({
                    key: nguoiDung.id,
                    label: nguoiDung.ten_dai_dien,
                    children: <p>
                        {xuatNoiDungTable(nguoiDung.id)}
                    </p>
                })
        }
    })



    return <div>
        <Divider />
        <div className='row'>
            <div className='col-2'>
                {checkQuyen() ?

                    <>
                        {themMoHinh == "1" ?
                            <Tag color='green'> Cho phép đề xuất: </Tag>
                            :
                            <Tag color='red'> Khóa đề xuất: </Tag>
                        }

                        <Switch checked={+themMoHinh} onChange={(value) => {

                            suaCauHinhAPI("THEM_MO_HINH", { gia_tri: value ? "1" : "0" }).then(res => {
                                getCauHinhAPI("THEM_MO_HINH").then(res => {

                                    setThemMoHinh(res.gia_tri)
                                })
                            })
                        }} />

                        <button className='mt-2 btn btn-success' onClick={() => { setOpen(true) }}>Thêm danh mục</button>
                    </>

                    :

                    themMoHinh == "1" &&

                    <button className='btn btn-success' onClick={() => {
                        navigate("/update-mo-hinh")
                    }}>Thêm mô hình/giải pháp mới
                    </button>

                }

                <button className='mt-2 btn btn-outline-success' onClick={() => {
                    exportMoHinh(userLogin.id, checkQuyen())
                }}>Xuất danh sách</button>

            </div>

            <div className='col-2'>
                <label className='fw-bold'>
                    Tên mô hình:
                </label>
                <Search
                    placeholder="Nhập tên mô hình..."
                    allowClear
                    enterButton
                    defaultValue={searchParams.get("tmh")}
                    onSearch={(value) => {
                        searchParams.set('tmh', value);
                        setSearchParams(searchParams);
                    }}
                />
            </div>

            <div className='col-2'>
                <label className='fw-bold'>
                    Thời gian:
                </label>
                <select className='form-control' onChange={(event) => {

                    searchParams.set('time', event.target.value);
                    setSearchParams(searchParams);
                }}
                    defaultValue={searchParams.get("time")}
                >
                    <option value="">Tất cả</option>
                    {lstYear.sort((a, b) => b - a).map(item => {
                        return <option value={item}>{item}</option>
                    })}
                </select>
            </div>
            <div className='col-2'>
                <label className='fw-bold'>
                    Xét duyệt:
                </label>
                <select className='form-control' onChange={(event) => {
                    searchParams.set('xd', event.target.value);

                    setSearchParams(searchParams);
                }}
                    defaultValue={searchParams.get("xd")}
                >
                    <option value="">Tất cả</option>
                    <option value={0}>Chưa duyệt</option>
                    <option value={1}>Đã duyệt</option>

                </select>
            </div>
        </div>
        <Divider />

        {/*  */}
        {checkQuyen() ?
            <Collapse items={itemCollapse} />
            :
            xuatNoiDungTable(userLogin.id)
        }

        {/*  */}

        <Drawer
            title={`Quản lý danh mục mô hình/giải pháp`}
            placement="right"
            width={"50%"}
            onClose={onClose}
            open={open}
        >
            <FormDanhMucMoHinh />
        </Drawer>
    </div>
};

export default MoHinhGiaiPhap;