import { api } from "./api";


// khen-thuong
export const getKhenThuongApi = async (maDanhMuc) => {
    const { data } = await api.get(`/khen-thuong/${maDanhMuc}`);
    return data;
};
export const createKhenThuongApi = async (model) => {
    const { data } = await api.post(`/khen-thuong`, model);
    return data;
};

// khen-thuong-chi-tiet
export const getKhenThuongChiTietApi = async (khenThuongId) => {
    const { data } = await api.get(`/khen-thuong/chi-tiet/${khenThuongId}`);
    return data;
};

export const themKhenThuongChiTietApi = async (model) => {
    const { data } = await api.post('/khen-thuong/chi-tiet', model);
    return data;
};

export const suaKhenThuongChiTietApi = async (id, model) => {
    const { data } = await api.put(`/khen-thuong/chi-tiet/${id}`, model);
    return data;
};

export const xoaKhenThuongChiTietApi = async (id) => {
    const { data } = await api.delete(`/khen-thuong/chi-tiet/${id}`);
    return data;
};


// remove file
export const removeFileApi = async (fileName) => {
    const { data } = await api.delete(`/file/remove-file/${fileName}`);
    return data;
};

// danh-muc-khen-thuong
export const getDanhMucKhenThuongApi = async () => {
    const { data } = await api.get('/danh-muc-khen-thuong');
    return data;
};

export const themDanhMucKhenThuongApi = async (model) => {
    const { data } = await api.post('/danh-muc-khen-thuong', model);
    return data;
};

export const suaDanhMucKhenThuongApi = async (id, model) => {
    const { data } = await api.put(`/danh-muc-khen-thuong/${id}`, model);
    return data;
};

export const xoaMucKhenThuongApi = async (id) => {
    const { data } = await api.delete(`/danh-muc-khen-thuong/${id}`);
    return data;
};






