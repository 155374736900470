import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { themBangDiemChiTietApi } from '../api/bangDiemApi';
import { getBangDiemChiTietDanhMucDis } from '../redux/bangDiemReducer';
import { message } from 'antd';


const FormDiemChiTietHuyen = ({ bangDiemChiTiet, bangDiem, onClose }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schema = yup.object().shape({


  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm
    ({
      resolver: yupResolver(schema),
    });


  const onSubmit = data => {

    if (data.diem_cap_huyen > bangDiem.diem_chuan) {
      message.error("Điểm chấm không được lớn hơn điểm chuẩn !!")
      return;
    }


    let model = {}
    if (bangDiemChiTiet) {
      model = {
        id: bangDiemChiTiet.id,
        nguoi_dung_id: bangDiemChiTiet.nguoi_dung_id,
        hoat_dong_minh_chung: bangDiemChiTiet.hoat_dong_minh_chung,
        giai_trinh: bangDiemChiTiet.giai_trinh,
        diem_tu_cham: +bangDiemChiTiet.diem_tu_cham,
        bang_diem_id: +bangDiemChiTiet.bang_diem_id,

        diem_cap_huyen: data.diem_cap_huyen == "" ? 0 : +data.diem_cap_huyen,
        phan_hoi_cap_huyen: data.phan_hoi_cap_huyen,
      }

      themBangDiemChiTietApi(model).then(res => {
        dispatch(getBangDiemChiTietDanhMucDis(bangDiem.ma_danh_muc))
        message.success("Đã thêm nội dung mới !")
        onClose()
      }).catch(err => {
        message.error("Đã xãy ra lỗi !")
      })
    }



  }

  useEffect(() => {

    reset(bangDiemChiTiet)


  }, [bangDiemChiTiet, bangDiemChiTiet?.id])


  return (
    <div>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 col-6">
          <label className="form-label">Tên đại diện: </label>
          <b> {bangDiemChiTiet.nguoi_dung.ten_dai_dien}</b>

        </div>
        <div className="mb-3 col-6">
          <label className="form-label">Điểm tự chấm: </label>
          <b> {bangDiemChiTiet.diem_tu_cham}</b>

        </div>
        <div className="mb-3 col-6">
          <label className="form-label">Hoạt động minh chứng: </label>
          <b> {bangDiemChiTiet.hoat_dong_minh_chung}</b>

        </div>
        <div className="mb-3 col-6">
          <label className="form-label">Giải trình: </label>
          <b> {bangDiemChiTiet.giai_trinh}</b>
        </div>

        <div className="mb-3 col-12">
          <label className="form-label">Điểm cấp huyện</label>
          <input className="form-control"  {...register('diem_cap_huyen')} />
          {errors.diem_cap_huyen && <p className='text-danger'>{errors.diem_cap_huyen?.message}</p>}

        </div>
        <div className="mb-3 col-12">
          <label className="form-label">Phản hồi cấp huyện</label>
          <textarea rows={10} className="form-control"  {...register('phan_hoi_cap_huyen')} />
          {errors.phan_hoi_cap_huyen && <p className='text-danger'>{errors.phan_hoi_cap_huyen?.message}</p>}

        </div>
        <div className="mb-3 col-2">
          <button type="submit" className="btn btn-primary">Lưu</button>
        </div>
        <div className="mb-3 col-3">
          <button type='button' className='btn btn-outline-primary' onClick={() => {
            reset({
              diem_tu_cham: "", hoat_dong_minh_chung: "", giai_trinh: ""
            })
          }}>Làm mới</button>
        </div>

      </form>
    </div>
  )
}


export default FormDiemChiTietHuyen