import { Button, Divider, Drawer, Input, Popconfirm, Space, Switch, Table, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { checkQuyen, formatDate } from '../utils/config';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNguoiDungDis } from '../redux/nguoiDungReducer';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { FormNguoiDung } from '../components/FormNguoiDung';
import { capNhatNguoiDungAPI, xoaNguoiDungAPI } from '../api/nguoiDungApi';



const { Search } = Input;


const ThongTinNguoiDung = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Tìm
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        đóng
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    
    // ======================



    const [nguoiDung, setNguoiDung] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        if (!checkQuyen()) {
            navigate("/login")
        }

        dispatch(getNguoiDungDis())

    }, [])

    const { data } = useSelector((state) => state.nguoiDungReducer);


    const columns = [
        {
            title: <>
                <Popconfirm
                    showCancel={false}
                    title="Thông tin"
                    description="Dùng để khóa tài khoản. Nếu không kích hoạt, người dùng sẽ không login được !"

                >
                    Tên đại diện <i className='fas fa-question-circle'></i>
                </Popconfirm>
            </>,
            key: 'ten_dai_dien',
            ...getColumnSearchProps('ten_dai_dien'),
            render: (_, record) => {
                return record.ten_dai_dien
            }
        },
        {
            title: 'Email',
            key: 'email',
            ...getColumnSearchProps('email'),

            render: (_, record) => {
                return record.email
            }
        },
        {
            title: 'Điện thoại',
            key: 'so_dien_thoai',
            ...getColumnSearchProps('so_dien_thoai'),

            render: (_, record) => {
                return record.so_dien_thoai
            }
        },
        {
            title: 'Địa chỉ',
            key: 'dia_chi',
            ...getColumnSearchProps('dia_chi'),

            render: (_, record) => {
                return record.dia_chi
            }
        },
        {
            title: 'Ngày tạo',
            key: 'ngay_tao',
            ...getColumnSearchProps('ngay_tao'),

            render: (_, record) => {
                return formatDate(record.ngay_tao)
            }
        },

        {
            title: 'Quyền',
            key: 'quyen',
            ...getColumnSearchProps('quyen'),

            render: (_, record) => {
                return record.quyen == "ADMIN" ? "Quản lý" : "người dùng"
            }
        },
        {
            title: <>
                <Popconfirm
                    showCancel={false}
                    title="Thông tin"
                    description="Dùng để khóa tài khoản. Nếu không kích hoạt, người dùng sẽ không login được !"

                >Kích hoạt <i className='fas fa-question-circle'></i></Popconfirm>
            </>,
            key: 'kich_hoat',
            render: (_, record) => {

                return <Switch checked={record?.kich_hoat} onChange={(value) => {
                    let model = { ...record, kich_hoat: value }
                    capNhatNguoiDungAPI(model.id, model).then(res => {
                        message.success("Thay đổi thành công")
                        dispatch(getNguoiDungDis())


                    }).catch(err => {
                        message.error(err.response.data.message)
                        console.log(err)
                    })
                }} />
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <>
                    <button className='btn btn-sm btn-primary mx-2 '
                        onClick={() => {
                            setNguoiDung(record)
                            setOpen(true);
                        }}
                    ><i className='fas fa-edit'></i> Sửa</button>

                    <Popconfirm
                        title="Bạn chắc muốn tiếp tục ?"
                        onConfirm={() => {
                            xoaNguoiDungAPI(record.id).then(res => {
                                dispatch(getNguoiDungDis())
                                message.success("Xóa thành công !")
                            })
                        }}
                        okText="Có"
                        cancelText="Không"
                    >
                        <button className='btn btn-sm btn-danger mx-2'><i className='fas fa-trash'></i> Xóa</button>
                    </Popconfirm>
                </>
            }
        },
    ]


    return (
        <div>
            <>
                <Divider />
                <p className='container'>
                    {checkQuyen() && <button className='btn btn-success mx-2' onClick={() => {
                        setOpen(true)
                        setNguoiDung({ id: 0 })
                    }} ><i className='fas fa-plus'></i>  Thêm tin mới</button>}
                    {/* 
                    <Search
                        placeholder="Tiêu đề tin"
                        allowClear
                        enterButton="Tìm tin"
                        size="large"
                        className='mx-2'
                        style={{ width: 300 }}
                        onSearch={(value) => {
                            setTieuDe(value)
                        }}
                    /> */}
                </p>
                <Divider />
            </>
            <Table columns={columns} dataSource={data} />

            <Drawer
                title={`Nội dung người dùng`}
                placement="right"
                width={"50%"}
                onClose={onClose}
                open={open}
            >
                <FormNguoiDung nguoiDung={nguoiDung} />
            </Drawer>
        </div>
    )
}

export default ThongTinNguoiDung