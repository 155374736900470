import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import { BE_DOMAIN, uploadImageAPI } from '../api/api';
import { message } from 'antd';

// Cài đặt module ImageUploader cho Quill
Quill.register('modules/imageUploader', ImageUploader);


const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link', 'image'], // Nút chèn hình ảnh và liên kết
        ['clean']
    ],
    imageUploader: {
        upload: file => {

            // if (file.type != "image/jpeg" && file.type != "image/png" && file.type != "image/jpg") {
            //     message.error("Hình hỗ trợ: JPG, PNG, JPEG !")
            //     return
            // }

            if (file.size > 2000000) {
                message.error('Chỉ cho phép hình dưới, 2MB');

                return new Promise((resolve, reject) => { })
            }

            return new Promise((resolve, reject) => {
                // Kiểm tra kích thước file

                const formData = new FormData();
                formData.append('file', file);

                uploadImageAPI(formData).then(res => {
                    resolve(res)
                }).catch(error => {
                    message.error('Upload thất bại');
                    reject('Upload thất bại');
                    console.error('Error:', error);
                });
            });
        }
    }
};

const formats = [
    'header', 'font', 'list', 'bold', 'italic', 'underline', 'color', 'background', 'align', 'link', 'image'
];

const QuillEditor = ({ value, onChange }) => {


    return (
        <ReactQuill
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
            theme="snow"
        />
    );
};

export default QuillEditor;
