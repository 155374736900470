export const checkQuyen = () => {
    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))
    if (userLogin.quyen == "ADMIN") return true
    return false
}

export const truncateText = (text, wordLimit) => {
    // Tách chuỗi thành một mảng các từ
    const words = text.split(' ');

    // Kiểm tra nếu số lượng từ trong chuỗi nhỏ hơn hoặc bằng wordLimit
    if (words.length <= wordLimit) {
        return text;
    }

    // Ghép lại các từ cho đến khi đạt đến wordLimit
    const truncatedWords = words.slice(0, wordLimit);
    const truncatedText = truncatedWords.join(' ') + '...';

    return truncatedText;
}

// date show
export const formatDate = (dateT) => {
    // Tạo đối tượng Date từ chuỗi ISO 8601
    const date = new Date(dateT);

    // Hàm điền số 0 phía trước nếu cần
    const padZero = (num) => (num < 10 ? `0${num}` : num);

    // Tạo các thành phần của ngày theo định dạng mong muốn
    const dayFormatted = padZero(date.getUTCDate());
    const monthFormatted = padZero(date.getUTCMonth() + 1); // Tháng trong JavaScript là 0-11
    const yearFormatted = date.getUTCFullYear();

    // Thay thế các phần trong định dạng mong muốn
    return `${dayFormatted}/${monthFormatted}/${yearFormatted}`
}

export const convertTextToHtml = (text) => {
    const html = text.split('\n').map((line, index) => (
        <span key={index}>{line}<br /></span>
    ));
    return html;
};