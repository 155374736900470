import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getDanhMucMoHinhDis } from '../redux/moHinhReducer';
import { Collapse, Divider, Popconfirm, Tag, message } from 'antd';
import { suaDanhMucApi, themDanhMucApi, xoaDanhMucApi } from '../api/moHinhApi';

const FormDanhMucMoHinh = () => {
    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))
    const { dataDanhMuc } = useSelector((state) => state.moHinhReducer);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [data, setData] = useState(false);
    const [tieuDe, setTieuDe] = useState("");
    const { id } = useParams()

    useEffect(() => {
        dispatch(getDanhMucMoHinhDis())
    }, [])

    
    const loadThem = (ma_loai) => {
        return <Popconfirm
            onPopupClick={(event) => event.stopPropagation()}
            title="Thêm danh mục"
            description={<>
                <input className='form-control' value={tieuDe} onChange={(event) => {

                    setTieuDe(event.target.value)

                }} />
            </>}
            onConfirm={() => {
                if (tieuDe != "") {
                    let model = {
                        ten_danh_muc: tieuDe,
                        ma_loai
                    }
                    themDanhMucApi(model).then(res => {

                        dispatch(getDanhMucMoHinhDis())
                        setTieuDe("")
                    })
                } else {
                    message.error("Hãy nhập tiêu đề !!")
                }


            }}
            okText="Lưu"
            cancelText="hủy"
        ><button className='btn btn-sm btn-success' onClick={(event) => event.stopPropagation()}> Thêm </button>
        </Popconfirm>
    }

    const loadUpdate = (data) => {
        return <>
            <Popconfirm
                title="Thay đổi tiêu đề ?"
                description={<>
                    <input className='form-control' value={tieuDe} onChange={(event) => {
                        setTieuDe(event.target.value)
                    }} />
                </>}
                onConfirm={() => {
                    if (tieuDe != "") {
                        let model = {
                            ...data,
                            ten_danh_muc: tieuDe
                        }
                        suaDanhMucApi(data.id, model).then(res => {

                            dispatch(getDanhMucMoHinhDis())
                            setTieuDe("")
                        })
                    } else {
                        message.error("Hãy nhập tiêu đề !!")
                    }


                }}
                okText="Có"
                cancelText="Không"
            >
                <Tag className='mx-2' color='blue' onClick={() => {

                }}><i className='fas fa-edit'></i></Tag>
            </Popconfirm>


            <Popconfirm
                title="Bạn chắc muốn tiếp tục ?"
                onConfirm={() => {
                    xoaDanhMucApi(data.id).then(res => {

                        dispatch(getDanhMucMoHinhDis())

                    })

                }}
                okText="Có"
                cancelText="Không"
            >
                <Tag color='red'><i className='fas fa-trash'></i></Tag>
            </Popconfirm>
        </>
    }

    const items = [
        {
            key: '1',
            label: <>Loại mô hình {loadThem("LOAI_MO_HINH")} </>,
            children: <p>
                {dataDanhMuc.filter(n => n.ma_loai == "LOAI_MO_HINH").map(item => {
                    return <>
                        {loadUpdate(item)}
                        {item.ten_danh_muc}
                        <Divider />
                    </>
                })}
            </p>,
        },
        {
            key: '2',
            label: <>Quy mô {loadThem("QUY_MO")}</>,
            children: <p>
                {dataDanhMuc.filter(n => n.ma_loai == "QUY_MO").map(item => {
                    return <>
                        {loadUpdate(item)}
                        {item.ten_danh_muc}
                        <Divider />
                    </>
                })}
            </p>,
        },
        {
            key: '3',
            label: <>Mảng hoạt động {loadThem("MANG_HOAT_DONG")}</>,
            children: <p>
                {dataDanhMuc.filter(n => n.ma_loai == "MANG_HOAT_DONG").map(item => {
                    return <>
                        {loadUpdate(item)}
                        {item.ten_danh_muc}
                        <Divider />
                    </>
                })}
            </p>,
        },
    ];

    return (
        <div>
            <Collapse items={items} />


        </div>
    )
}

export default FormDanhMucMoHinh