import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import QuillEditor from './QuillEditor';
import { BE_DOMAIN, BE_DOMAIN_IMG, createTinTucAPI, updateTinTucAPI } from '../api/api';
import { Switch, Upload, message } from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getTinTucDetailDis, getTinTucDis } from '../redux/tinTucReducer';
import { useDispatch } from 'react-redux';




const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Chỉ có thể up JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('File phải dưới 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

export const FormTinTuc = ({ tinTuc }) => {
    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

    const [noiBat, setNoiBat] = useState(false);

    const [editorQuill, setEditorQuill] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const schema = yup.object().shape({

        tieu_de: yup.string().required("Tên đăng nhập không được trống"),

    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const defaultValues = {
        tieu_de: '',
    };

    const dispatch = useDispatch();

    const onSubmit = data => {
        let { tieu_de } = data
        // console.log(editorQuill)

        if (imageUrl == "" || editorQuill == "")
            return

        let model = {

            tieu_de,
            hinh_anh: imageUrl,
            noi_dung: editorQuill,
            tac_gia: userLogin.id,
            noi_bat: noiBat
        }

        if (tinTuc && tinTuc?.id == 0) {
            createTinTucAPI(model).then(res => {
                message.success("Thay đổi thành công")
                dispatch(getTinTucDis())

            }).catch(err => {
                message.error("Đã có lỗi xảy ra")
                console.log(err)
            })
        } else {
            console.log(model)

            updateTinTucAPI(tinTuc.id, model).then(res => {
                message.success("Thay đổi thành công")
                dispatch(getTinTucDis())
                dispatch(getTinTucDetailDis(tinTuc.id))

            }).catch(err => {
                message.error("Đã có lỗi xảy ra")
                console.log(err)
            })
        }


    };

    useEffect(() => {
        // console.log(tinTuc)
        if (!tinTuc || tinTuc?.id == 0) {
            reset(defaultValues)
            setImageUrl("")
            setEditorQuill("")
            setNoiBat(false)
        } else {
            defaultValues.tieu_de = tinTuc.tieu_de
            reset(defaultValues)
            setImageUrl(tinTuc.hinh_anh)
            setEditorQuill(tinTuc.noi_dung)
            setNoiBat(tinTuc.noi_bat)

        }
    }, [tinTuc, tinTuc?.id])


    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);

            setImageUrl(info.file.response);


        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <div>
            <form className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 col-4">
                    <label className="form-label">Tiêu đề</label>
                    <input className="form-control"  {...register('tieu_de')} />
                    {errors.tieu_de && <p className='text-danger'>{errors.tieu_de?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label">Hình bìa</label>
                    {imageUrl == "" && <p className='text-danger'>Hình bìa không được trống !</p>}
                    <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${BE_DOMAIN}/file/upload-img`}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        {imageUrl ? <img src={BE_DOMAIN_IMG + imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>



                </div>
                <div className="mb-3 col-4">
                    <label className="form-label">Nổi bật</label> <br />
                    <Switch checked={noiBat} onChange={(value) => setNoiBat(value)} />

                </div>

                <div className="mb-3">
                    <label className="form-label">Nội dung</label>
                    {editorQuill == "" && <p className='text-danger'>Nội dung không được trống !</p>}
                    <QuillEditor onChange={setEditorQuill} value={editorQuill} />


                </div>



                <button type="submit" className="btn btn-primary">Lưu</button>
            </form>
        </div>
    )
}
