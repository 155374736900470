import { Avatar, Dropdown, Menu, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/img/logo.png'
import { history } from '../index.js'
import { checkQuyen } from '../utils/config.js';
import FormDoiMatKhau from '../components/FormDoiMatKhau.jsx';
import Loading from '../components/loading/Loading.jsx';

const TemplateNotLogin = () => {
    const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

    const userName = userLogin ? userLogin.ten_dai_dien : "Random"


    let items = [];
    if (userLogin) {
        if (checkQuyen()) {
            items.push({
                label: <>
                    <a className='text-decoration-none' href='#' onClick={() => {

                        navigate("/thong-tin-nguoi-dung")

                    }}><i className='fas fa-user'>
                        </i> Quản lý tài khoản</a>
                </>,
                key: '1'
            },)
        }
        items.push(

            {
                label: <><a className='text-decoration-none' href='#' onClick={() => {
                    setIsModalOpen(true)
                }}><i className='fas fa-unlock-alt'>
                    </i> Đổi mật khẩu</a>
                </>,
                key: '2'
            },
            {
                label: <><a className='text-decoration-none' href='#' onClick={() => {
                    localStorage.removeItem("LOGIN_USER")
                    navigate("/login")

                }}><i className="fas fa-sign-out-alt"></i> Đăng xuất</a></>,
                key: '3'
            }
        )
    } else {
        items.push({
            label: <>
                <a className='text-decoration-none' href='#' onClick={() => {

                    navigate("/login")

                }}><i className='fa-solid fa-right-to-bracket'>
                    </i> Đăng nhập</a>
            </>,
            key: '1'
        },)
    }


    return (
        <div className='container'>
            <div className='row mb-5 shadow'>
                <div className='col-1'>
                    <a href='#' onClick={() => navigate("/")}>
                        <img width={100} src={logo} className='pointer' />
                    </a>
                </div>
                <div className='col-10'>
                    <Menu mode="horizontal" onClick={({ key }) => navigate(`/${key}`)} items={
                        [
                            {
                                label: <> <label style={{ cursor: "pointer" }} className='h6 bold' onClick={() => navigate("/")}>  <i className="fas fa-home"></i> Tin tức</label>  </>,
                                key: '',
                            },
                            {
                                label: <> <label style={{ cursor: "pointer" }} className='h6 bold' onClick={() => navigate("/")}>  <i className="fas fa-pencil-ruler"></i> Bảng điểm </label>  </>,
                                key: 'bang-diem',
                            },
                            {
                                label: <> <label style={{ cursor: "pointer" }} className='h6 bold' onClick={() => navigate("/khen-thuong")}>  <i className="fas fa-scroll"></i> Khen thưởng</label>  </>,
                                key: 'khen-thuong',
                            },
                            {
                                label: <><label className='h6 bold'><i className="fas fa-users"></i> Hoạt động đoàn <i className="fas fa-chevron-down"></i></label></>,
                                key: 'hoat-dong-doan',

                                children: [
                                    {
                                        key: "mo-hinh-giai-phap",
                                        label: <><label style={{ cursor: "pointer" }} className='h6 bold' onClick={() => navigate("/mo-hinh-giai-phap")}><i className="fas fa-info-circle"></i> Mô hình - Giải pháp</label></>

                                    },
                                ],
                            },
                        ]
                    } />
                </div>



                <div className='col-1'>

                    <Dropdown
                        menu={
                            {
                                items
                            }
                        }
                    >
                        <Avatar size="large" src={`https://ui-avatars.com/api/?name=${userName}&background=random&bold=true`} />
                    </Dropdown>
                </div>
            </div>

            <Outlet />
            {/* <h1>footer</h1> */}

            <Modal title="Đổi mật khẩu" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null}>
                <FormDoiMatKhau />
            </Modal>
            <Loading />
        </div>
    )
}

export default TemplateNotLogin