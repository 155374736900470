import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


import { message } from 'antd';
import { checkQuyen } from '../utils/config';
import { getKhenThuongChiTietDis, getKhenThuongDis } from '../redux/khenThuongReducer';
import { suaKhenThuongChiTietApi, themKhenThuongChiTietApi } from '../api/khenThuongApi';


const FormKhenThuong = ({ khenThuong, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams()


  let userInfo = localStorage.getItem('LOGIN_USER');
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }
    
  const schema = yup.object().shape({

    ten_day_du: yup.string().required("Không được trống"),

  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm
    ({
      resolver: yupResolver(schema),
    });

  const onSubmit = data => {

    let model = {
      ...khenThuong,
      ...data,
      loai: +data.loai,
      ma_danh_muc: +id
    }


    if (khenThuong.id == 0) {
      delete model.id

      model = { ...model, nguoi_dung_id: userInfo.id }
      console.log(model)

      themKhenThuongChiTietApi(model).then(res => {

        dispatch(getKhenThuongChiTietDis(khenThuong.khen_thuong_id))
        dispatch(getKhenThuongDis(id))

        message.success("Đã thêm nội dung mới !")
        onClose()
      }).catch(err => {
        message.error("Đã xãy ra lỗi !")
      })
    } else {
      delete model.ma_danh_muc

      suaKhenThuongChiTietApi(model.id, model).then(res => {

        dispatch(getKhenThuongChiTietDis(khenThuong.khen_thuong_id))
        dispatch(getKhenThuongDis(id))

        message.success("Cập nhật thành công !")
        onClose()
      }).catch(err => {
        message.error("Đã xãy ra lỗi !")
      })
    }

  }

  let defaultValue = {
    id: 0,

    ten_day_du: "",
    loai: 0,
    chuc_vu: "",
    link_minh_chung:"",
    da_duyet: false,
    nhan_xet_cua_vp: "",
    nhan_xet_phu_trach: ""
  }

  useEffect(() => {
    if (!khenThuong || khenThuong?.id == 0)
      reset(defaultValue)
    else
      reset(khenThuong)

  }, [khenThuong, khenThuong.id])

  return (
    <div>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>

        <div className="mb-3 col-12">
          <label className="form-label">Loại</label>
          <select className="form-control"  {...register('loai')} >
            <option value={0}>Cá nhân</option>
            <option value={1}>Đơn vị</option>
          </select>


        </div>
        <div className="mb-3 col-12">
          <label className="form-label">Tên đầy đủ</label>
          <input className="form-control"  {...register('ten_day_du')} />
          {errors.ten_day_du && <p className='text-danger'>{errors.ten_day_du?.message}</p>}

        </div>
        <div className="mb-3 col-12">
          <label className="form-label">Chức vụ</label>
          <input className="form-control"  {...register('chuc_vu')} />
          {errors.chuc_vu && <p className='text-danger'>{errors.chuc_vu?.message}</p>}

        </div>

        <div className="mb-3 col-12">
          <label className="form-label">Link minh chứng</label>
          <input className="form-control"  {...register('link_minh_chung')} />
          {errors.link_minh_chung && <p className='text-danger'>{errors.link_minh_chung?.message}</p>}

        </div>

        {checkQuyen() && <>

          <div className="mb-3 col-12">
            <label className="form-label">Nhận xét của VP</label>
            <textarea rows={10} className="form-control"  {...register('nhan_xet_cua_vp')} />
            {errors.nhan_xet_cua_vp && <p className='text-danger'>{errors.nhan_xet_cua_vp?.message}</p>}

          </div>
          <div className="mb-3 col-12">
            <label className="form-label">Nhận xét phụ trách</label>
            <textarea rows={10} className="form-control"  {...register('nhan_xet_phu_trach')} />
            {errors.nhan_xet_phu_trach && <p className='text-danger'>{errors.nhan_xet_phu_trach?.message}</p>}

          </div>
        </>}

        <button type="submit" className="btn btn-primary">Lưu</button>
      </form>
    </div>
  )
}

export default FormKhenThuong