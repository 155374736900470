import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTinTucDis } from '../redux/tinTucReducer';
import { Divider, Drawer, Input, Popconfirm, message } from 'antd';
import { checkQuyen, formatDate, truncateText } from '../utils/config';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { FormTinTuc } from '../components/FormTinTuc';
import { BE_DOMAIN_IMG, removeTinTucAPI } from '../api/api';
import parse from 'html-react-parser';

const { Search } = Input;

const TinTuc = () => {
    const [tieuDe, setTieuDe] = useState("")
    const [tinTuc, setTinTuc] = useState(null)

    let userInfo = localStorage.getItem('LOGIN_USER');

    if (userInfo) {
        userInfo = JSON.parse(userInfo);
    }

    const { data } = useSelector((state) => state.tinTucReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(getTinTucDis())
    }, [])

    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };


    let dataTin = data;

    if (tieuDe && data) {
        dataTin = dataTin.filter(n => n.tieu_de.toLowerCase().trim().indexOf(tieuDe.toLowerCase().trim()) != -1)
    }

    const hienThiUpdate = (tinTuc) => {

        return userInfo && checkQuyen() && <p>
            <button className='btn btn-sm btn-primary mx-2' onClick={() => {
                setTinTuc(tinTuc)
                setOpen(true);
            }}>
                <i className='fas fa-edit'></i> Sửa
            </button>
            <Popconfirm
                title="Bạn chắc muốn tiếp tục ?"
                onConfirm={() => {
                    removeTinTucAPI(tinTuc.id).then(res => {
                        dispatch(getTinTucDis())
                        message.success("Xóa thành công !")
                    })
                }}
                okText="Có"
                cancelText="Không"
            >
                <button className='btn btn-sm btn-danger mx-2'>
                    <i className='fas fa-trash'></i> Xóa
                </button>
            </Popconfirm>
        </p>

    }

    return (
        <div className='row'>

            <>
                <Divider />
                <p className='container'>
                    {userInfo && checkQuyen() && <button className='btn btn-success mx-2' onClick={() => {
                        setOpen(true)
                        setTinTuc({ id: 0 })
                    }} > Thêm tin mới</button>}

                    <Search
                        placeholder="Tiêu đề tin"
                        allowClear
                        enterButton="Tìm tin"
                        size="large"
                        className='mx-2'
                        style={{ width: 300 }}
                        onSearch={(value) => {
                            setTieuDe(value)
                        }}
                    />
                </p>
                <Divider />
            </>


            {
                dataTin?.map((item, index) => {

                    if (index == 0) {
                        return <div className='col-md-8' >
                            {hienThiUpdate(item)}
                            <a style={{ cursor: "pointer" }} onClick={() => { navigate(`/chi-tiet-tin/${item.id}`) }} >
                                <div className="image-container" >
                                    <img src={`${BE_DOMAIN_IMG + item.hinh_anh}`} height={500} width={"100%"} />
                                    {item.noi_bat && <div className="label">Nổi bật</div>}
                                </div>

                                <h2 className='fw-bold'>

                                    {item.tieu_de}
                                </h2>
                            </a>
                        </div>

                    }

                    if (index == 1) {
                        return <div className='col-md-4' >
                            {hienThiUpdate(item)}
                            <a style={{ cursor: "pointer" }} onClick={() => { navigate(`/chi-tiet-tin/${item.id}`) }} >
                                <div className="image-container">
                                    <img src={`${BE_DOMAIN_IMG + item.hinh_anh}`} height={500} width={"100%"} />
                                    {item.noi_bat && <div className="label">Nổi bật</div>}
                                </div>

                                <h2 className='fw-bold'>

                                    {item.tieu_de}
                                </h2>
                            </a>
                        </div>

                    }

                    return <div className='col-md-12 row pt-4' >
                        <Divider />
                        {hienThiUpdate(item)}

                        <div className='col-3'>
                            <a style={{ cursor: "pointer" }} onClick={() => { navigate(`/chi-tiet-tin/${item.id}`) }} >
                                <div className="image-container">
                                    <img src={`${BE_DOMAIN_IMG + item.hinh_anh}`} height={200} width={"100%"} />
                                    {item.noi_bat && <div className="label">Nổi bật</div>}
                                </div>
                            </a>
                        </div>
                        <div className='col-9'>
                            <a style={{ cursor: "pointer" }} onClick={() => { navigate(`/chi-tiet-tin/${item.id}`) }} >
                                {formatDate(new Date()) == formatDate(item.ngay_dang) && <span className='h6 text-success'> [Mới đăng] </span>}
                                <h4 className='fw-bold'>

                                    {item.tieu_de}
                                </h4>
                                <span className='text-secondary '>
                                    {formatDate(item.ngay_dang)}
                                </span>
                                <h6 className='mt-2'>
                                    {item.noi_dung && parse(truncateText(item.noi_dung, 50))}
                                </h6>
                            </a>
                        </div>



                    </div>

                })
            }
            <Drawer
                title={`Nội dung tin`}
                placement="right"
                width={"50%"}
                onClose={onClose}
                open={open}
            >
                <FormTinTuc tinTuc={tinTuc} />
            </Drawer>
        </div >
    )
}

export default TinTuc