// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import exampleReducer from '../redux/exampleSlice';
import tinTucReducer from '../redux/tinTucReducer'
import nguoiDungReducer from './nguoiDungReducer';
import bangDiemReducer from './bangDiemReducer';
import moHinhReducer from './moHinhReducer';
import khenThuongReducer from './khenThuongReducer';

const store = configureStore({
  reducer: {
    example: exampleReducer,
    tinTucReducer: tinTucReducer,
    nguoiDungReducer: nguoiDungReducer,
    bangDiemReducer: bangDiemReducer,
    moHinhReducer: moHinhReducer,
    khenThuongReducer: khenThuongReducer

  },
});

export default store;
