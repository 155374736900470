import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { Breadcrumb, DatePicker, Divider, InputNumber, Select, Switch, Upload, message } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { suaMoHinhApi, themMoHinhApi } from '../api/moHinhApi';
import { getDanhMucMoHinhDis, getMoHinhDis, getMoHinhNguoiDungDis } from '../redux/moHinhReducer';
import { checkQuyen } from '../utils/config';


const { RangePicker } = DatePicker;


export const FormMoHinhGiaiPhap = () => {
    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))
    const { data: lstData } = useSelector((state) => state.moHinhReducer);

    const { dataDanhMuc } = useSelector((state) => state.moHinhReducer);

    const navigate = useNavigate();

    const [data, setData] = useState(false);
    const { id } = useParams()


    const schema = yup.object().shape({

        loai_mo_hinh: yup.string().required("Trường này không được trống"),
        quy_mo: yup.string().required("Trường này không được trống"),
        mang_hoat_dong: yup.string().required("Trường này không được trống"),
        ten_mo_hinh: yup.string().required("Trường này không được trống"),
        don_vi_to_chuc: yup.string().required("Trường này không được trống"),
        ly_do_thuc_hien: yup.string().required("Trường này không được trống"),
        tom_tat_mo_hinh: yup.string().required("Trường này không được trống"),
        link_van_ban: yup.string().required("Trường này không được trống"),
        link_minh_chung: yup.string().required("Trường này không được trống"),
        danh_gia_hieu_qua: yup.string().required("Trường này không được trống"),

    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const defaultValues = {
        loai_mo_hinh: '',
        quy_mo: '',
        mang_hoat_dong: '',
        ten_mo_hinh: '',
        don_vi_to_chuc: '',
        ly_do_thuc_hien: '',
        tom_tat_mo_hinh: '',
        link_van_ban: '',
        danh_gia_hieu_qua: '',
        link_minh_chung: '',


        so_luong_tham_gia: 0,
        kinh_phi: 0,
        ngay_bat_dau: "",
        ngay_ket_thuc: "",

        nguoi_dung_id: userLogin.id,
        dang_ky: false,
        da_duyet: false,

        ngay_tao: new Date()
    };

    const dispatch = useDispatch();

    const changeMohinh = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }

    const onSubmit = getData => {
        let { ten_dai_dien, email, mat_khau, so_dien_thoai, dia_chi, quyen } = getData

        let model = { ...getData, loai_mo_hinh: +getData.loai_mo_hinh, quy_mo: +getData.quy_mo, mang_hoat_dong: +getData.mang_hoat_dong, ...data, kinh_phi: data.kinh_phi.toString() }

        if (!id) {
            themMoHinhApi(model).then(res => {
                message.success("Thay đổi thành công")
                dispatch(getMoHinhNguoiDungDis(userLogin.id))

            }).catch(err => {
                message.error(err.response.data.message)
                console.log(err)
            })
        } else {

            suaMoHinhApi(id, model).then(res => {
                message.success("Thay đổi thành công")
                dispatch(getMoHinhNguoiDungDis(userLogin.id))


            }).catch(err => {
                message.error(err.response.data.message)
                console.log(err)
            })
        }


    };

    useEffect(() => {

        if (checkQuyen())
            dispatch(getMoHinhDis())
        else
            dispatch(getMoHinhNguoiDungDis(userLogin.id))

        dispatch(getDanhMucMoHinhDis())

    }, [])

    useEffect(() => {
        // console.log(tinTuc)
        if (!id) {
            reset(defaultValues)

        } else {
            let moHinh = lstData.find(n => n.id == id);
            if (moHinh) {
                
                reset(moHinh)
                setData({
                    so_luong_tham_gia: moHinh.so_luong_tham_gia,
                    kinh_phi: moHinh.kinh_phi,
                    ngay_bat_dau: moHinh.ngay_bat_dau,
                    ngay_ket_thuc: moHinh.ngay_ket_thuc,

                })
            }


        }
    }, [lstData, id])

    return (
        <div>
            <Breadcrumb
                items={[

                    {
                        title: <a onClick={() => {
                            navigate("/mo-hinh-giai-phap")
                        }}>Mô hình/giải pháp</a>,
                    },
                    {
                        title: 'Cập nhật mô hình/giải pháp',
                    },
                ]}
            />
            <Divider />
            <h1>
                {checkQuyen() && "Nội dung chi tiết mô hình/giải pháp"}
                {!checkQuyen() && (id ? "Chỉnh sửa mô hình/giải pháp" : "Thêm mô hình/giải pháp")}
            </h1>
            <Divider />
            <form className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Loại mô hình</label>
                    <select className="form-control"  {...register('loai_mo_hinh')} >
                        {dataDanhMuc.filter(n => n.ma_loai == "LOAI_MO_HINH").map(item => <option value={item.id}> {item.ten_danh_muc} </option>)}

                    </select>
                    {errors.loai_mo_hinh && <p className='text-danger'>{errors.loai_mo_hinh?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Quy mô hoạt động</label>
                    <select className="form-control"  {...register('quy_mo')} >
                        {dataDanhMuc.filter(n => n.ma_loai == "QUY_MO").map(item => <option value={item.id}> {item.ten_danh_muc} </option>)}
                    </select>
                    {errors.quy_mo && <p className='text-danger'>{errors.quy_mo?.message}</p>}

                </div>


                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Mảng hoạt động</label>
                    <select className="form-control"  {...register('mang_hoat_dong')} >
                        {dataDanhMuc.filter(n => n.ma_loai == "MANG_HOAT_DONG").map(item => <option value={item.id}> {item.ten_danh_muc} </option>)}
                    </select>
                    {errors.mang_hoat_dong && <p className='text-danger'>{errors.mang_hoat_dong?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label fw-bold ">Tên mô hình/giải pháp</label>
                    <input placeholder='Ngày hội Những người Cộng sản trẻ...' className="form-control"  {...register('ten_mo_hinh')} />
                    {errors.ten_mo_hinh && <p className='text-danger'>{errors.ten_mo_hinh?.message}</p>}

                </div>


                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Đơn vị tổ chức</label>
                    <input placeholder='Đoàn khoa HTTT, Chi Đoàn CLS1...' className="form-control"  {...register('don_vi_to_chuc')} />
                    {errors.don_vi_to_chuc && <p className='text-danger'>{errors.don_vi_to_chuc?.message}</p>}

                </div>


                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Số lượng tham gia</label>
                    <br />
                    <InputNumber
                        max={1000000}
                        style={{ width: "100%" }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        value={data.so_luong_tham_gia}
                        onChange={(value) => { changeMohinh("so_luong_tham_gia", value) }}
                    />

                    {errors.so_luong_tham_gia && <p className='text-danger'>{errors.so_luong_tham_gia?.message}</p>}

                </div>


                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Kinh phí (VNĐ)</label>
                    <InputNumber
                        style={{ width: "100%" }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        value={data.kinh_phi}
                        onChange={(value) => { changeMohinh("kinh_phi", value) }}
                    />
                    {errors.kinh_phi && <p className='text-danger'>{errors.kinh_phi?.message}</p>}

                </div>

                <div className="mb-3 col-8">
                    <label className="form-label fw-bold">Ngày bắt đầu - kết thúc</label>
                    <RangePicker placeholder={["Bắt đầu", "Kết thúc"]} style={{ width: "100%" }} format="DD/MM/YYYY"

                        value={data.ngay_bat_dau && [dayjs(data.ngay_bat_dau, "YYYY/MM/DD"), dayjs(data.ngay_ket_thuc, "YYYY/MM/DD")]}

                        onChange={(date, dateString) => {

                            setData({
                                ...data,
                                ngay_bat_dau: date[0],
                                ngay_ket_thuc: date[1]
                            })
                        }}
                    />
                    {errors.bat_dau_ket_thuc && <p className='text-danger'>{errors.bat_dau_ket_thuc?.message}</p>}

                </div>


                <div className="mb-3 col-6">
                    <label className="form-label fw-bold">Lý do thực hiện / Thực trạng đơn vị</label>
                    <textarea placeholder='Mô tả ngắn gọn các vấn đề khó khăn của đơn vị hoặc thực trạng cần tháo gỡ' rows={10} className="form-control"  {...register('ly_do_thuc_hien')} />
                    {errors.ly_do_thuc_hien && <p className='text-danger'>{errors.ly_do_thuc_hien?.message}</p>}

                </div>

                <div className="mb-3 col-6">
                    <label className="form-label fw-bold">Tóm tắt mô hình / giải pháp</label>
                    <textarea placeholder='Nêu nội dung, điểm nổi bật của mô hình/giải pháp. Tối đa 7000 ký tự' rows={10} className="form-control"  {...register('tom_tat_mo_hinh')} />
                    {errors.tom_tat_mo_hinh && <p className='text-danger'>{errors.tom_tat_mo_hinh?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Link văn bản</label>
                    <textarea placeholder='Link bài viết hoặc hình ảnh trên trang web, fanpage, google drive...' className="form-control"  {...register('link_van_ban')} />
                    {errors.link_van_ban && <p className='text-danger'>{errors.link_van_ban?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Link minh chứng</label>
                    <textarea placeholder='Link bài viết hoặc hình ảnh trên trang web, fanpage, google drive...' className="form-control"  {...register('link_minh_chung')} />
                    {errors.link_minh_chung && <p className='text-danger'>{errors.link_minh_chung?.message}</p>}

                </div>


                <div className="mb-3 col-4">
                    <label className="form-label fw-bold">Đánh giá hiểu quả hoạt động</label>
                    <textarea placeholder='Lợi ích đem đến cho người tham gia, phản ánh của người tham gia' className="form-control"  {...register('danh_gia_hieu_qua')} />
                    {errors.danh_gia_hieu_qua && <p className='text-danger'>{errors.danh_gia_hieu_qua?.message}</p>}

                </div>
                {/* 
                <div className="mb-3 col-4">
                    <label className="form-label">Quyền</label>
                    <select className="form-control"  {...register('quyen')} >
                        <option value="USER"> USER </option>
                        <option value="ADMIN"> ADMIN </option>
                    </select>
                </div>

                <div className="mb-3 col-12">
                    <label className="form-label">Địa chỉ</label>
                    <textarea className="form-control"  {...register('dia_chi')} />
                    {errors.dia_chi && <p className='text-danger'>{errors.dia_chi?.message}</p>}

                </div> */}

                {!checkQuyen() && <button type="submit" className="btn btn-primary">Lưu</button>}

            </form>
        </div>
    )
}
