import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { suaBangDiemApi, themBangDiemApi } from '../api/bangDiemApi';
import { getBangDiemDis } from '../redux/bangDiemReducer';
import { message } from 'antd';


const FormDiemBangDiem = ({ bangDiem, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schema = yup.object().shape({

    tieu_de: yup.string().required("Không được trống"),
    diem_chuan: yup.number()
      .typeError("Điểm chuẩn phải là một số")
      .required("Không được trống")
      .integer("Điểm chuẩn phải là số nguyên")
      .notOneOf([0], "Điểm chuẩn phải khác 0"),
    noi_dung: yup.string().required("Không được trống"),

  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm
    ({
      resolver: yupResolver(schema),
    });

  const onSubmit = data => {

    let model = {
      ...data,
      bang_diem_cha: bangDiem.bang_diem_cha,
      ma_danh_muc: bangDiem.ma_danh_muc,

    }
    if (bangDiem.id == 0) {
      delete model.id

      themBangDiemApi(model).then(res => {

        dispatch(getBangDiemDis(bangDiem.ma_danh_muc))
        message.success("Đã thêm nội dung mới !")
        onClose()
      }).catch(err => {
        message.error("Đã xãy ra lỗi !")
      })
    } else {
      delete model.dc_xoa

      suaBangDiemApi(model.id, model).then(res => {

        dispatch(getBangDiemDis(bangDiem.ma_danh_muc))
        message.success("Cập nhật thành công !")
        onClose()
      }).catch(err => {
        message.error("Đã xãy ra lỗi !")
      })
    }

  }

  useEffect(() => {
    reset(bangDiem)
  }, [bangDiem, bangDiem.id])

  return (
    <div>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 col-12">
          <label className="form-label">Tiêu đề</label>
          <input className="form-control"  {...register('tieu_de')} />
          {errors.tieu_de && <p className='text-danger'>{errors.tieu_de?.message}</p>}

        </div>
        <div className="mb-3 col-12">
          <label className="form-label">Điểm chuẩn</label>
          <input className="form-control"  {...register('diem_chuan')} />
          {errors.diem_chuan && <p className='text-danger'>{errors.diem_chuan?.message}</p>}

        </div>
        <div className="mb-3 col-12">
          <label className="form-label">Nội dung</label>
          <textarea rows={10} className="form-control"  {...register('noi_dung')} />
          {errors.noi_dung && <p className='text-danger'>{errors.noi_dung?.message}</p>}

        </div>
        <button type="submit" className="btn btn-primary">Lưu</button>
      </form>
    </div>
  )
}

export default FormDiemBangDiem