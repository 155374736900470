import { Divider } from 'antd';
import React from 'react';

const HomePage = () => {
    return <div>
        <h1>Thông tin đơn vị</h1>
        <Divider />
        
    </div>
};

export default HomePage;