import React from 'react'
import logo from '../assets/img/logo.png'

//form

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { loginAPI } from '../api/api';
import { message } from 'antd';


const Login = () => {

    const schema = yup.object().shape({
        // email: yup.string().email('Email không hợp lệ').required('Email là bắt buộc'),
        // phone: yup.string().matches(/^[0-9]{10}$/, 'Số điện thoại phải gồm 10 chữ số').required('Số điện thoại là bắt buộc'),
        tenDangNhap: yup.string().required("Tên đăng nhập không được trống"),
        matKhau: yup.string().required("Mật khẩu không được trống")
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate();

    const onSubmit = data => {
        let { tenDangNhap, matKhau } = data

        let model = {
            email: tenDangNhap,
            so_dien_thoai: tenDangNhap,
            mat_khau: matKhau
        }
        loginAPI(model).then(res => {

            
            localStorage.setItem("LOGIN_USER", JSON.stringify(res))
            navigate("/")
        }).catch(err => {
            console.log(err.response)
            message.error(err.response.data.message)
        })



    };

    //form

    return (
        <div className=''>
            <div className='div_bg_login'></div>
            <div className='div_mark_login'></div>
            <div className='div_right_login_1'></div>
            <div className='div_right_login_2'></div>
            <div className='div_right_login_3'></div>
            <div className='div_right_login_4'></div>
            <div className='div_right_login_5'></div>
            <div className='div_right_login_6'></div>

            <div className='row container  div_login' style={{ paddingTop: "10%", marginLeft: "10%" }}>
                <div className='col-xs-10 col-md-10 col-lg-6 text-white'>
                    <p className='text-center'>

                        <img src={logo} width={200} />
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label className="form-label">Email / Số điện thoại</label>
                            <input className="form-control"  {...register('tenDangNhap')} />
                            {errors.email && <p className='text-danger'>{errors.tenDangNhap?.message}</p>}

                        </div>
                        <div className="mb-3">
                            <label className="form-label">Mật khẩu</label>
                            <input type='password' className="form-control"  {...register('matKhau')} />
                            {errors.phone && <p className='text-danger'>{errors.matKhau?.message}</p>}

                        </div>


                        <button type="submit" className="btn btn-primary">Đăng nhập</button>
                    </form>

                </div>

            </div>



        </div>
    )
}

export default Login