import { api } from "./api";

export const exportMoHinh = async (userId, quyen) => {
    try {
        const response = await api.get(`/mo-hinh-giai-phap/export/${userId}/${quyen}`, {
            responseType: 'blob', // Đảm bảo rằng phản hồi được nhận dưới dạng blob
        });

        // Tạo URL cho blob và kích hoạt tải xuống
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', new Date().getTime() + '_mo-hinh-giai-phap.xlsx'); // Tên file khi tải xuống
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file', error);
    }
};

// moHinh


export const getMoHinhApi = async () => {
    const { data } = await api.get(`/mo-hinh-giai-phap/mo-hinh-dang-ky`);
    return data;
};


export const getMoHinhNguoiDungApi = async (nguoiDungId) => {
    const { data } = await api.get(`/mo-hinh-giai-phap/mo-hinh-dang-ky/${nguoiDungId}`);
    return data;
};

export const themMoHinhApi = async (model) => {
    const { data } = await api.post(`/mo-hinh-giai-phap`, model);
    return data;
};

export const suaMoHinhApi = async (id, model) => {
    const { data } = await api.put(`/mo-hinh-giai-phap/${id}`, model);
    return data;
};

export const xoaMoHinhApi = async (id) => {
    const { data } = await api.delete(`/mo-hinh-giai-phap/${id}`);
    return data;
};


// danh muc
export const getDanhMucAllApi = async () => {
    const { data } = await api.get(`/danh-muc-mo-hinh`);
    return data;
};

export const getDanhMucApi = async (maLoai) => {
    const { data } = await api.get(`/danh-muc-mo-hinh/${maLoai}`);
    return data;
};


export const themDanhMucApi = async (model) => {
    const { data } = await api.post(`/danh-muc-mo-hinh`, model);
    return data;
};

export const suaDanhMucApi = async (id, model) => {
    const { data } = await api.put(`/danh-muc-mo-hinh/${id}`, model);
    return data;
};

export const xoaDanhMucApi = async (id) => {
    const { data } = await api.delete(`/danh-muc-mo-hinh/${id}`);
    return data;
};






