import { api } from "./api";

export const getNguoiDungAPI = async () => {
    const { data } = await api.get('/nguoi-dung');
    return data;
};



export const themNguoiDungAPI = async (model) => {
    const { data } = await api.post('/nguoi-dung', model);
    return data;
};



export const capNhatNguoiDungAPI = async (id, model) => {
    const { data } = await api.put(`/nguoi-dung/${id}`, model);
    return data;
};


export const xoaNguoiDungAPI = async (id) => {
    const { data } = await api.delete(`/nguoi-dung/${id}`);
    return data;
};


