import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import QuillEditor from './QuillEditor';
import { BE_DOMAIN, BE_DOMAIN_IMG, createTinTucAPI, updateTinTucAPI } from '../api/api';
import { Select, Switch, Upload, message } from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getTinTucDetailDis, getTinTucDis } from '../redux/tinTucReducer';
import { useDispatch } from 'react-redux';
import { capNhatNguoiDungAPI, themNguoiDungAPI } from '../api/nguoiDungApi';
import { getNguoiDungDis } from '../redux/nguoiDungReducer';



export const FormNguoiDung = ({ nguoiDung }) => {
    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

    const [noiBat, setNoiBat] = useState(false);


    const schema = yup.object().shape({

        ten_dai_dien: yup.string().required("Tên đại diện không được trống"),
        email: yup.string().email('Email không hợp lệ').required('Email là bắt buộc'),
        so_dien_thoai: yup.string().matches(/^[0-9]{9,}$/, 'Số điện thoại phải có ít nhất 9 chữ số').required('Số điện thoại là bắt buộc'),

        mat_khau: yup.string().required("Mật khẩu không được trống"),

        dia_chi: yup.string().required("Địa chỉ không được trống"),

    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const defaultValues = {
        ten_dai_dien: '',
        email: '',
        mat_khau: '',
        so_dien_thoai: '',
        dia_chi: '',
        quyen: 'USER',
        kich_hoat: true
    };

    const dispatch = useDispatch();

    const onSubmit = data => {
        let { ten_dai_dien, email, mat_khau, so_dien_thoai, dia_chi, quyen } = data

        let model = {
            ten_dai_dien,
            email,
            mat_khau,
            so_dien_thoai,
            dia_chi,
            quyen,
            kich_hoat: true
        }

        if (nguoiDung && nguoiDung?.id == 0) {
            themNguoiDungAPI(model).then(res => {
                message.success("Thay đổi thành công")
                dispatch(getNguoiDungDis())

            }).catch(err => {
                message.error(err.response.data.message)
                console.log(err)
            })
        } else {

            capNhatNguoiDungAPI(nguoiDung.id, model).then(res => {
                message.success("Thay đổi thành công")
                dispatch(getNguoiDungDis())


            }).catch(err => {
                message.error(err.response.data.message)
                console.log(err)
            })
        }


    };

    useEffect(() => {
        // console.log(tinTuc)
        if (!nguoiDung || nguoiDung?.id == 0) {
            reset(defaultValues)

        } else {

            reset(nguoiDung)


        }
    }, [nguoiDung, nguoiDung?.id])



    return (
        <div>
            <form className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3 col-4">
                    <label className="form-label">Tên đại diện</label>
                    <input className="form-control"  {...register('ten_dai_dien')} />
                    {errors.ten_dai_dien && <p className='text-danger'>{errors.ten_dai_dien?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label">Email</label>
                    <input className="form-control"  {...register('email')} />
                    {errors.email && <p className='text-danger'>{errors.email?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label">Điện thoại</label>
                    <input className="form-control"  {...register('so_dien_thoai')} />
                    {errors.so_dien_thoai && <p className='text-danger'>{errors.so_dien_thoai?.message}</p>}

                </div>

                <div className="mb-3 col-4">
                    <label className="form-label">Mật khẩu</label>
                    <input type="password" className="form-control"  {...register('mat_khau')} />
                    {errors.mat_khau && <p className='text-danger'>{errors.mat_khau?.message}</p>}

                </div>


                <div className="mb-3 col-4">
                    <label className="form-label">Quyền</label>
                    <select className="form-control"  {...register('quyen')} >
                        <option value="USER"> USER </option>
                        <option value="ADMIN"> ADMIN </option>
                    </select>
                </div>

                <div className="mb-3 col-12">
                    <label className="form-label">Địa chỉ</label>
                    <textarea className="form-control"  {...register('dia_chi')} />
                    {errors.dia_chi && <p className='text-danger'>{errors.dia_chi?.message}</p>}

                </div>


                <button type="submit" className="btn btn-primary">Lưu</button>
            </form>
        </div>
    )
}
