import { Drawer, Table, Tag } from 'antd';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import FormDiemChiTietHuyen from './FormDiemChiTietHuyen';

const HuyenChamDiem = ({ bangDiem }) => {

    const { dataBangDiemChiTietDanhMuc } = useSelector((state) => state.bangDiemReducer);
    const [bangDiemChiTiet, setBangDiemChiTiet] = useState(null)
    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    const xuatTableBangDiem = () => {


        let tongDiemTuCham = 0;
        let tongDiemCapHuyen = 0;


        let dataTable = dataBangDiemChiTietDanhMuc?.filter(n => n.bang_diem_id == bangDiem.id)

        dataTable.map(n => {
            tongDiemTuCham += n.diem_tu_cham
            tongDiemCapHuyen += n.diem_cap_huyen


        })

        let columns = [
            {
                title: 'Tên đại diện',
                key: 'tieu_de',
                render: (_, record) => {
                    return <>
                        {record.nguoi_dung.ten_dai_dien}
                    </>
                }
            },
            {
                title: <> Điểm tự chấm <Tag color='red'>{tongDiemTuCham}</Tag></>,
                key: 'diem_tu_cham',
                render: (_, record) => {
                    return <>
                        <Tag color='blue'>

                            {record.diem_tu_cham}
                        </Tag>
                    </>
                }
            },
            {
                title: 'Hoạt động minh chứng',
                key: 'hoat_dong_minh_chung',
                render: (_, record) => {
                    return <>
                        {record.hoat_dong_minh_chung}
                    </>
                }
            },
            {
                title: 'Giải trình',
                key: 'giai_trinh',
                render: (_, record) => {
                    return <>
                        {record.giai_trinh}
                    </>
                }
            },
            {
                title: <> Phản hồi cấp huyện <Tag color='red'>{tongDiemCapHuyen}</Tag></>,
                key: 'phan_hoi_cap_huyen',
                render: (_, record) => {
                    return <>

                        <label>Điểm: <b>
                            <Tag color='blue'>
                                {record.diem_cap_huyen}
                            </Tag>
                        </b>

                        </label>
                        <br />
                        <label>Phản hồi: <b>{record.phan_hoi_cap_huyen}</b> </label>

                    </>
                }
            },
            {
                title: ' ',
                key: 'action',
                render: (_, record) => {

                    return <>
                        <button className='btn btn-sm btn-success m-2' onClick={() => {
                            setBangDiemChiTiet(record)
                            setOpen(true)

                        }}>
                            <i className='fas fa-edit'></i> Chấm điểm
                        </button>

                    </>
                }
            }
        ]



        return <Table columns={columns} dataSource={dataTable} />
    }

    return (
        <div>
            {xuatTableBangDiem()}

            <Drawer
                title={`Chấm điểm`}
                placement="right"
                width={"30%"}
                onClose={onClose}
                open={open}
            >
                <FormDiemChiTietHuyen bangDiemChiTiet={bangDiemChiTiet} bangDiem={bangDiem} onClose={onClose} />
            </Drawer>
        </div>
    )
}

export default HuyenChamDiem