import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getTinTucDetailAPI, removeTinTucAPI } from '../api/api'
import { Drawer, Popconfirm, Skeleton, Tag, message } from 'antd'
import { checkQuyen, formatDate } from '../utils/config'
import parse from 'html-react-parser';
import { getTinTucDetailDis, getTinTucDis } from '../redux/tinTucReducer'
import { FormTinTuc } from '../components/FormTinTuc'
import { useDispatch, useSelector } from 'react-redux'

const TinTucDetail = () => {

    const { id } = useParams()
    // const [tinTuc, setTinTuc] = useState({});
    const dispatch = useDispatch();

    const { tinTucDetail: tinTuc } = useSelector((state) => state.tinTucReducer);

    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

    const [open, setOpen] = useState(false);
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {

        dispatch(getTinTucDetailDis(id))

        // getTinTucDetailAPI(id).then(res => {

        //     setTinTuc(res)
        // })
    }, [id])

    const navigate = useNavigate()

    const hienThiUpdate = (tinTuc) => {

        return userLogin && checkQuyen() && <p>
            <button className='btn btn-sm btn-primary mx-2' onClick={() => {

                setOpen(true);
            }}>
                <i className='fas fa-edit'></i> Sửa
            </button>
            <Popconfirm
                title="Bạn chắc muốn tiếp tục ?"
                onConfirm={() => {
                    removeTinTucAPI(tinTuc.id).then(res => {
                        dispatch(getTinTucDis())
                        message.success("Xóa thành công !")
                        navigate("/tin-tuc")
                    })
                }}
                okText="Có"
                cancelText="Không"
            >
                <button className='btn btn-sm btn-danger mx-2'>
                    <i className='fas fa-trash'></i> Xóa
                </button>
            </Popconfirm>
        </p>

    }

    return tinTuc ?
        <div>
            {hienThiUpdate(tinTuc)}

            {formatDate(new Date()) == formatDate(tinTuc.ngay_dang) && <span className='h6 text-success'> [Mới đăng] </span>}
            <h1 className='fw-bold'>
                {tinTuc.tieu_de}
            </h1>
            <h6 className='text-secondary'>
                {formatDate(tinTuc.ngay_dang)} -  {tinTuc.noi_bat && <Tag color="red">Nổi bật </Tag>}
            </h6>
            <p>
                {tinTuc.noi_dung && parse(tinTuc.noi_dung)}
            </p>
            <Drawer
                title={`Nội dung tin`}
                placement="right"
                width={"50%"}
                onClose={onClose}
                open={open}
            >
                <FormTinTuc tinTuc={tinTuc} />
            </Drawer>
        </div >
        :
        <Skeleton />

}

export default TinTucDetail