import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import store from './redux/store';
import { Provider } from 'react-redux';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import {
  Route,
  unstable_HistoryRouter as HistoryRouter,
  Routes,
} from 'react-router-dom';
import { createBrowserHistory } from "history";
import Template from './Template/Template';
import Login from './pages/Login';
import TinTuc from './pages/TinTuc';
import TinTucDetail from './pages/TinTucDetail';
import ThongTinNguoiDung from './pages/ThongTinNguoiDung';

import BangDiem from './pages/BangDiem';
import MoHinhGiaiPhap from './pages/MoHinhGiaiPhap';
import { FormMoHinhGiaiPhap } from './pages/FormMoHinhGiaiPhap';
import KhenThuong from './pages/KhenThuong';
import TemplateNotLogin from './Template/TemplateNotLogin';


const root = ReactDOM.createRoot(document.getElementById('root'));

export const history = createBrowserHistory();

root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>

      <Routes>
        <Route exact path="/login" element={<Login />} />

        <Route element={<TemplateNotLogin />} >
          <Route exact path="/" element={<TinTuc />} />
          <Route path="/chi-tiet-tin/:id" element={<TinTucDetail />} />

        </Route>

        <Route element={<Template />} >
          {/* <Route exact path="/" element={<TinTuc />} /> */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/khen-thuong" element={<KhenThuong />} />
          <Route path="/khen-thuong/:id" element={<KhenThuong />} />

          <Route path="/mo-hinh-giai-phap" element={<MoHinhGiaiPhap />} />
          <Route path="/update-mo-hinh" element={<FormMoHinhGiaiPhap />} />
          <Route path="/update-mo-hinh/:id" element={<FormMoHinhGiaiPhap />} />

          <Route path="/thong-tin-nguoi-dung" element={<ThongTinNguoiDung />} />

          {/* <Route path="/chi-tiet-tin/:id" element={<TinTucDetail />} /> */}

          <Route path="/bang-diem" element={<BangDiem />} />
          <Route path="/bang-diem/:id" element={<BangDiem />} />

          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </HistoryRouter>
  </Provider>
);


