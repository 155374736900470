import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { doiMatKhauAPI } from '../api/api';

const schema = yup.object().shape({
    matKhauCu: yup.string().required("Mật khẩu cũ không được trống"),
    matKhauMoi: yup.string().required("Mật khẩu mới không được trống"),
    matKhauMoiConfirm: yup.string()
        .oneOf([yup.ref('matKhauMoi'), null], 'Mật khẩu mới không khớp')
        .required('Xác nhận mật khẩu mới là bắt buộc')
});

const FormDoiMatKhau = () => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate();

    const onSubmit = data => {
        let { matKhauCu, matKhauMoi } = data;

        let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

        let model = {
            id: userLogin.id,
            mat_khau_cu: matKhauCu,
            mat_khau: matKhauMoi
        };

        doiMatKhauAPI(model).then(res => {
            message.success("Đổi mật khẩu thành công. Vui lòng đăng nhập lại !");
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        }).catch(err => {
            console.log(err.response);
            message.error(err.response.data.message);
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="mb-3">
                    <label className="form-label">Mật khẩu cũ</label>
                    <input type='password' className="form-control"  {...register('matKhauCu')} />
                    {errors.matKhauCu && <p className='text-danger'>{errors.matKhauCu?.message}</p>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Mật khẩu mới</label>
                    <input type='password' className="form-control"  {...register('matKhauMoi')} />
                    {errors.matKhauMoi && <p className='text-danger'>{errors.matKhauMoi?.message}</p>}
                </div>
                <div className="mb-3">
                    <label className="form-label">Xác nhận mật khẩu mới</label>
                    <input type='password' className="form-control"  {...register('matKhauMoiConfirm')} />
                    {errors.matKhauMoiConfirm && <p className='text-danger'>{errors.matKhauMoiConfirm?.message}</p>}
                </div>
                <button type="submit" className="btn btn-primary">Đổi mật khẩu</button>
            </form>

        </div>
    )
}

export default FormDoiMatKhau