

import { createSlice } from '@reduxjs/toolkit';

import { getNguoiDungAPI } from '../api/nguoiDungApi';

const initialState = {
  data: [],
};

const nguoiDungReducer = createSlice({
  name: 'nguoiDungReducer',
  initialState,
  reducers: {

    getNguoiDungActions(state, action) {

      state.data = action.payload;
    },

  },
});

export const { getNguoiDungActions } = nguoiDungReducer.actions;

export default nguoiDungReducer.reducer;



export const getNguoiDungDis = () => {
  return async (dispatch) => {
    const result = await getNguoiDungAPI();
    const action = getNguoiDungActions(result);
    dispatch(action);
  };
};



