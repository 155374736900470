

import { createSlice } from '@reduxjs/toolkit';
import { getDanhMucAllApi, getMoHinhApi, getMoHinhNguoiDungApi } from '../api/moHinhApi';


const initialState = {
  data: [],
  dataDanhMuc: []
};

const moHinhReducer = createSlice({
  name: 'moHinhReducer',
  initialState,
  reducers: {

    getMoHinhActions(state, action) {

      state.data = action.payload;
    },
    getMoHinhNguoiDungActions(state, action) {

      state.data = action.payload;
    },

    getDanhMucActions(state, action) {

      state.dataDanhMuc = action.payload;
    },

  },
});

export const { getMoHinhActions, getMoHinhNguoiDungActions, getDanhMucActions
} = moHinhReducer.actions;

export default moHinhReducer.reducer;


export const getMoHinhDis = () => {
  return async (dispatch) => {
    const result = await getMoHinhApi();
    const action = getMoHinhActions(result);
    dispatch(action);
  };
};


export const getMoHinhNguoiDungDis = (nguoiDungId) => {
  return async (dispatch) => {
    const result = await getMoHinhNguoiDungApi(nguoiDungId);
    const action = getMoHinhNguoiDungActions(result);
    dispatch(action);
  };
};


export const getDanhMucMoHinhDis = () => {
  return async (dispatch) => {
    const result = await getDanhMucAllApi();
    const action = getDanhMucActions(result);
    dispatch(action);
  };
};